import { useIntl } from 'gatsby-plugin-intl';
import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import { unsubscribe } from '../../api/unsubscribe';
import { Message, Types as MessageTypes } from '../ui/form/Message';

interface Props {
	hash: string;
	email: string;
}

export const UnsubScribeForm: FC<Props> = ({ hash, email }) => {
	const intl = useIntl();
	const [submitting, setSubmitting] = useState(false);
	const [messages, setMessages] = useState([]);

	const handleUnsubscribe = async () => {
		setSubmitting(true);
		try {
			await unsubscribe(hash, email);
			setMessages([
				{
					type: MessageTypes.SUCCESS,
					message: intl.formatMessage({ id: 'unsubscribe.successMessage' }),
				},
			]);
		} catch ({ messageId, values }) {
			setMessages([
				{
					type: MessageTypes.ERROR,
					message: intl.formatMessage({ id: messageId }, values),
				},
			]);
		}
		setSubmitting(false);
	};

	return (
		<>
			<div className="grid grid--center">
				<div className="grid__cell grid__cell--auto">
					<Link to="/" className="btn">
						<span className="btn__text">Ne</span>
					</Link>
				</div>
				<div className="grid__cell grid__cell--auto">
					<button type="button" className="btn" onClick={handleUnsubscribe} disabled={submitting}>
						<span className="btn__text">Ano</span>
					</button>
				</div>
			</div>
			<div className="grid grid--center u-mb-md">
				<div className="grid__cell grid__cell--auto">
					{messages.map((message, index) => (
						<Message key={index} type={message.type}>
							{message.message}
						</Message>
					))}
				</div>
			</div>
		</>
	);
};
