import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Container } from '../components/ui/block/Container';
import { Layout } from '../components/ui/Layout';
import { UnsubScribeForm } from '../components/organisms/UnsubscribeForm';
import loadable from '@loadable/component';

const Helmet = loadable(() => import('react-helmet'));
const Header = loadable(() => import('../components/layout/Header'));
const Paper = loadable(() => import('../components/layout/Paper'));

export default ({ location }) => {
	const params = new URLSearchParams(location.search);
	const hash = params.get('hash');
	const email = params.get('email');

	useEffect(() => {
		if (!(hash && email)) {
			navigate('/');
		}
	}, []);

	return (
		<Layout>
			<Helmet>
				<title>PageSpeed.cz - Odhlášení reportu</title>
			</Helmet>
			<Header />
			<Paper>
				<Container className="u-text-center">
					<h2 className="u-color-blue">
						Odhlášení reportu
						<small className="u-block">Opravdu chcete odhlásit odběr týdenního přehledu?</small>
					</h2>
					<UnsubScribeForm hash={hash} email={email} />
				</Container>
			</Paper>
		</Layout>
	);
};
