import { UNSUBSCRIBE_ENDPOINT } from './config';
import { UnsubscribeResponse } from './types';

export const unsubscribe = async (hash, email): Promise<UnsubscribeResponse> => {
	const apiUrl = `${UNSUBSCRIBE_ENDPOINT}?hash=${encodeURIComponent(hash)}&email=${encodeURIComponent(email)}`;

	const response = await fetch(apiUrl, { method: 'GET' });
	const data = await response.json();

	if (!response.ok) {
		const values = {};
		let messageId = 'responseError';
		throw { messageId, values };
	}

	return data;
};
